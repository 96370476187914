import Grid from '@mui/material/Grid'
import React from 'react'

const GridContainer = (props: any) => {
	const { children, ...rest } = props
	return (
		<Grid {...rest} container >
			{children}
		</Grid>
	)
}

export default GridContainer
