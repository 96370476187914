import GridContainer from '../../../components/basic/grid/GridContainer'
import GridItem from '../../../components/basic/grid/GridItem'
import { Typography } from "@mui/material"
import { ReactComponent as HomeIcon } from "../../../assets/icons/home.svg"
import { ReactComponent as UserCircleIcon } from "../../../assets/icons/user-circle.svg"
import { ReactComponent as BriefCaseIcon } from "../../../assets/icons/briefcase.svg"
import { ReactComponent as ArrowPathRoundedSquareIcon } from "../../../assets/icons/arrow-path-rounded-square.svg"
import { ReactComponent as ClipboardDocumentListIcon } from "../../../assets/icons/clipboard-document-list.svg"
import { useLanguage } from "../../../languageContext"

const styles = {
  containerFull: {
    width: "100%", height: "fit-content", display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap", justifyContent: "start"
  },
  iconAndText: {
    display: "flex",
    flexDirection: "row",
    mr: "40px",
    mb: { xs: 1, sm: 2, md: 3, lg: 3, xl: 3 },
    alignItems: "center",
  },
  textIcon: {
    color: "white",
    fontSize: "12px",
    mr: "10px",
  },
  imageIconText: {
    fontSize: { xs: 16, sm: 16, md: 19, lg: 19, xl: 19 },
  },
}

const ServicesIcons = () => {
  const { t } = useLanguage()

  return (

    <GridContainer sx={[styles.containerFull]}>
      <GridItem sx={[styles.iconAndText]}>
        <GridItem sx={styles.textIcon}>
          <HomeIcon width={24} height={24} />
        </GridItem>
        <Typography variant="body2" sx={styles.imageIconText}>
          {t("hosting")}
        </Typography>
      </GridItem>
      <GridItem sx={[styles.iconAndText]}>
        <GridItem sx={styles.textIcon}>
          <UserCircleIcon width={24} height={24} />
        </GridItem>
        <Typography variant="body2" sx={styles.imageIconText}>
          {t("support")}
        </Typography>
      </GridItem>
      <GridItem sx={[styles.iconAndText]}>
        <GridItem sx={styles.textIcon}>
          <BriefCaseIcon width={24} height={24} />
        </GridItem>
        <Typography variant="body2" sx={styles.imageIconText}>
          {t("beratung")}
        </Typography>
      </GridItem>
      <GridItem sx={[styles.iconAndText]}>
        <GridItem sx={styles.textIcon}>
          <ArrowPathRoundedSquareIcon width={24} height={24} />
        </GridItem>
        <Typography variant="body2" sx={styles.imageIconText}>
          {t("lifeCycleManagement")}
        </Typography>
      </GridItem>
      <GridItem sx={[styles.iconAndText]}>
        <GridItem sx={styles.textIcon}>
          <ClipboardDocumentListIcon width={24} height={24} />
        </GridItem>
        <Typography variant="body2" sx={styles.imageIconText}>
          {t("serviceLevelAgreements")}
        </Typography>
      </GridItem>
    </GridContainer>

  )
}
export default ServicesIcons
