import React from "react"
import "../../../src/customCss/carousel.css"

const Carousel = ({ logos }: any) => {
  return (
    <div className="slider">
      <div className="slide-track">
        {logos.map((logo: any, index: number) => (
          <div key={index} >
            <img src={logo} className="image" alt="" />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Carousel
