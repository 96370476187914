import React, { createContext, useContext, useState, ReactNode } from "react"
import translations from "./languages"

interface LanguageContextType {
  currentLanguage: string
  changeLanguage: (language: string) => void
  t: (key: string) => string
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined)

export function LanguageProvider({ children }: { children: ReactNode }) {
  const [currentLanguage, setCurrentLanguage] = useState<any>("en")

  const changeLanguage = (language: string) => {
    setCurrentLanguage(language)
  }

  const t = (key: string) => translations[currentLanguage as never][key] || key

  return <LanguageContext.Provider value={{ currentLanguage, changeLanguage, t }}>{children}</LanguageContext.Provider>
}

export function useLanguage() {
  const context = useContext(LanguageContext)
  if (context === undefined) {
    throw new Error("useLanguage must be used within a LanguageProvider")
  }
  return context
}
