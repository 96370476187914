import React from "react"
import { useNavigate } from "react-router-dom"
import GridItem from "../../components/basic/grid/GridItem"
import GridContainer from "../../components/basic/grid/GridContainer"
import { Box, Button, Link as MuiLink, Typography } from "@mui/material"
import container3 from "../../assets/images/after_programme.png"
import world_bg from "../../assets/images/world_bg.png"
import shape from "../../assets/images/shape.png"
import siotLogoWhite from "../../assets/images/siot-logo-vector-v2-white.png"
import industrialAutomationImage from "../../assets/images/industrialAutomation.png"
import smartBuildingImage from "../../assets/images/smartBuilding.png"
import smartEnergyImage from "../../assets/images/smartEnergy.png"
import smartTransportationImage from "../../assets/images/smartTransportation.png"
import smartCityImage from "../../assets/images/smartCity.png"
import assetTrackingImage from "../../assets/images/assetTracking.png"
import cityCloudImage from "../../assets/images/cityCloud.png"
import cloud from "../../assets/images/cloud.png"
import AdvantagesAccordion from "../../components/advanced/AdvantagesAccordion"
import Carousel from "../../components/carousel/Carousel"
import aliunidLogo from "../../assets/logos/aliunid.png"
import ntsLogo from "../../assets/logos/nts.png"
import inalpLogo from "../../assets/logos/inalp.png"
import bf from "../../assets/logos/bf.png"
import weidMuller from "../../assets/logos/weidmuller.png"
import ServicesIcons from "../../components/advanced/iconHolders/ServicesIcons"
import ProductIcons from "../../components/advanced/iconHolders/ProductIcons"
import { ReactComponent as BuildingLibraryIcon } from "../../assets/icons/building-library.svg"
import { ReactComponent as SparklesIcon } from "../../assets/icons/sparkles.svg"
import { ReactComponent as SquareStackIcon } from "../../assets/icons/square-stack.svg"
import { ReactComponent as CogIcon } from "../../assets/icons/cog.svg"
import { ReactComponent as HomeModernIcon } from "../../assets/icons/home-modern.svg"
import { ReactComponent as BoltIcon } from "../../assets/icons/bolt.svg"
import { ReactComponent as TruckIcon } from "../../assets/icons/truck.svg"
import { ReactComponent as BuildingOfficeIcon } from "../../assets/icons/building-office.svg"
import { ReactComponent as ViewFinderCircleIcon } from "../../assets/icons/viewfinder-circle.svg"
import { ReactComponent as IconCheck } from "../../assets/icons/check-blue.svg"
import { useLanguage } from "../../languageContext"

const logos = [
  ntsLogo,
  bf,
  weidMuller,
  aliunidLogo,
  inalpLogo,
  ntsLogo,
  bf,
  weidMuller,
  aliunidLogo,
  inalpLogo,
  ntsLogo,
  bf,
  weidMuller,
  aliunidLogo,
  inalpLogo,
  ntsLogo,
  bf,
  weidMuller,
  aliunidLogo,
  inalpLogo,
]

const styles = {
  container: {
    width: { xs: "90%", sm: "85%", md: "80%", lg: "80%", xl: "80%" },
    maxWidth: { xs: "90%", sm: "85%", md: "80%", lg: "80%", xl: 1300 },
    mb: "15px",
    flexGrow: "0",
    gap: "6%",
    margin: "0 auto",
  },
  container2: {
    width: "70%",
    marginX: "auto",
    display: "flex",
    flexWrap: "nowrap",
    gap: "40px",
  },
  containerFull: {
    width: "100%",
    height: "fit-content",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "center",
    pl: { xs: "15px", sm: "15px", md: "0", lg: "0", xl: "0" },
    pr: { xs: "15px", sm: "15px", md: "0", lg: "0", xl: "0" },
  },
  container1070: {
    width: { xs: "90%", sm: "85%", md: "80%", lg: "80%", xl: "80%" },
    maxWidth: { xs: "90%", sm: "85%", md: "80%", lg: "80%", xl: 1300 },
    margin: "0 auto",
    mb: "15px",
    flex: 1,
    flexWrap: "wrap",
    justifyContent: "left",
    alignItems: "center",
  },
  container1070_3: {
    width: "100%",
    maxWidth: "100%",
    mb: "15px",
    justifyContent: "space-between",
    display: "flex",
  },
  item: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  itemLastSection: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  itemBlue: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  itemBlueRight: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  item2: {
    flex: 1,
    maxWidth: { xs: 250, sm: 250, md: 350, lg: 350, xl: 400 },
    maxHeight: { xs: 250, sm: 250, md: 350, lg: 350, xl: 400 },
    paddingTop: "20px",
  },
  blueText: {
    color: "#346EA9",
    fontWeight: "bold",
    fontSize: "44px",
    lineHeight: "48px",
  },
  title: {
    fontWeight: "bold",
    fontSize: "44px",
    lineHeight: "48px",
    color: "white",
  },
  title2: {
    fontWeight: "bold",
    fontSize: "35px",
    lineHeight: "48px",
    marginTop: "10px",
    marginBottom: "30px",
  },
  subTitle: {
    fontWeight: "bold",
    fontSize: "20px",
    marginTop: "30px",
    marginBottom: "10px",
    color: "white",
  },
  subTitle2: {
    fontWeight: "bold",
    fontSize: "20px",
    marginBottom: "10px",
    color: "#868686",
  },
  subTitle3: {
    fontWeight: "bold",
    fontSize: "20px",
    marginBottom: "10px",
    color: "white",
  },
  text: {
    fontSize: { xs: 16, sm: 16, md: 19, lg: 19, xl: 19 },
    lineHeight: { xs: 1.5, sm: 1.5, md: 2, lg: 2, xl: 2 },
  },
  textSmall: {
    fontSize: "17px",
    fontWeight: "bold",
  },
  textWhite: {
    fontSize: "19px",
    lineHeight: "35px",
    color: "white",
  },
  textBlue: {
    fontSize: "19px",
    lineHeight: "35px",
    fontWeight: "bold",
  },
  partners: {
    fontSize: "20px",
    marginTop: { xs: "20px", sm: "20px", md: "20px", lg: "40px", xl: "40px" },
    marginBottom: { xs: "5px", sm: "20px", md: "20px", lg: "40px", xl: "40px" },
    // marginBottom: "40px",
    textAlign: "center",
    // width: "100%",
    // marginX: "auto",
  },
  button: {
    textTransform: "none",
    fontWeight: "bold",
    fontSize: { xs: 14, sm: 14, md: 16, lg: 17, xl: 17 },
    height: "52px",
    pl: "20px",
    pr: "20px",
    mt: "30px",
    borderRadius: "100px",
    background: "#082e55",
    color: "white",
    "&:hover": {
      backgroundColor: "white",
      transition: ".4s ease",
      color: "#082e55",
      borderColor: "#082e55",
    },
  },
  button1: {
    textTransform: "none",
    fontWeight: "bold",
    fontSize: { xs: 14, sm: 14, md: 16, lg: 17, xl: 17 },
    height: "48px",
    px: "15px",
    mr: "25px",
    borderRadius: "100px",
    backgroundColor: "white",
    color: "#082e55",
    border: "1px solid #082e55",
    "&:hover": {
      backgroundColor: "#082e55",
      transition: ".4s ease",
      color: "white",
      border: "1px solid #082e55",
    },
  },
  picture2: {
    backgroundImage: `url(${container3})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    height: "100%",
    width: "100%",
  },
  blueDivRight: {
    marginBottom: "20px",
    height: "190px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  blueContainer: {
    display: "flex",
  },
  highlited: {
    transform: { xs: "translateY(65px)", sm: "translateY(71px)", md: "translateY(75px)", lg: "translateY(77px)", xl: "translateY(77px)" },
    width: "100%",
    backgroundColor: "#346EA9",
    color: "white",
    padding: { xs: "15px 0", sm: "30px 0", md: "30px 0", lg: "30px 0", xl: "30px 0" },
    borderRadius: "15px",
    fontSize: { xs: 18, sm: 20, md: 32, lg: 38, xl: 38 },
    fontWeight: "bold",
    margin: "0 auto",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: { xs: "column", sm: "row", md: "row", lg: "row", xl: "row" },
  },
  backgroundBlack: {
    backgroundColor: "#212931",
    color: "white",
    backgroundImage: `url(${world_bg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right top",
    backgroundSize: "40%",
  },
  backgroundGray: {
    backgroundColor: "#fafafa",
  },
  backgrounBlue: {
    backgroundColor: "#082e55",
    color: "white",
  },
  backgroundBlueGradient: {
    background: "radial-gradient(#05A5DF, #1F4880)",
    color: "white",
  },
  item50: {
    flexBasis: { xs: "100%", sm: "100%", md: "500%", lg: "47%", xl: "47%" },
    flexWrap: "wrap",
    justifyContent: "left",
    alignItems: "center",
    flexDirection: "row",
  },
  item60: {
    flexBasis: { xs: "100%", sm: "100%", md: "100%", lg: "57%", xl: "57%" },
    flexWrap: "wrap",
    justifyContent: "left",
    alignItems: "center",
    flexDirection: "row",
  },
  item40: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    flexBasis: { xs: "100%", sm: "100%", md: "100%", lg: "37%", xl: "37%" },
  },
  solutionProblemRow: {
    display: "flex",
    flexDirection: { xs: "column", sm: "column", md: "column", lg: "row", xl: "row" },
    mb: "30px",
  },
  whiteBoldText: {
    color: "#ffffff",
    fontWeight: "bold",
    fontSize: { xs: 15, sm: 17, md: 19, lg: 20, xl: 20 },
  },
  textSolution: {
    backgroundColor: "#082e55",
    borderRadius: "20px",
    padding: "25px",
    color: "white",
    alignContent: "center",
    display: "flex",
    flexWrap: "wrap",
    minWidth: { xs: "100%", sm: "350px", md: "350px", lg: "350px", xl: "350px" },
    width: "100%",
  },
  textProblem: {
    alignContent: "center",
    display: "flex",
    flexWrap: "wrap",
    minWidth: { xs: "100%", sm: "350px", md: "350px", lg: "350px", xl: "350px" },
    width: "100%",
    fontSize: { xs: 15, sm: 17, md: 19, lg: 20, xl: 20 },
  },
  shape: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transform: { xs: "rotate(90deg)", sm: "rotate(90deg)", md: "rotate(90deg)", lg: "rotate(0deg)", xl: "rotate(0deg)" },
  },
  grayBoldText: {
    color: "#868686",
    fontWeight: "bold",
    fontSize: { xs: 17, sm: 17, md: 19, lg: 20, xl: 20 },
  },
  blueBoldText: {
    color: "#346EA9",
    fontWeight: "bold",
    fontSize: { xs: 17, sm: 17, md: 19, lg: 20, xl: 20 },
  },
  sectionHeading: {
    fontSize: { xs: 24, sm: 24, md: 32, lg: 38, xl: 38 },
    fontWeight: "bold",
    mb: "30px",
  },
  iconAndHeading: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    mt: "50px",
    width: "100%",
    mb: "30px",
  },
  headingIcon: {
    backgroundColor: "#346EA9",
    color: "white",
    borderRadius: "50%",
    padding: "12px",
    fontSize: "18px",
    fontWeight: "bold",
    mr: "20px",
    display: "flex",
  },
  headingText: {
    fontSize: { xs: 24, sm: 24, md: 32, lg: 38, xl: 38 },
    fontWeight: "bold",
  },
  iconsHolder: {
    backgroundColor: "#363D45",
    borderRadius: "25px",
    width: { xs: "100%", sm: "85%", md: "80%", lg: "80%", xl: "80%" },
    maxWidth: { xs: "100%", sm: "85%", md: "80%", lg: "80%", xl: 1300 },
    flexGrow: "0",
    padding: "50px 30px 10px",
    mb: "20px",
  },
  iconHolderHeading: {
    fontSize: "20px",
    fontWeight: "bold",
    mb: "30px",
    color: "white",
  },
  gap: {
    flexGrow: "1",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    mb: "20px",
  },
  whiteLine: {
    alignItems: "start",
    justifyContent: "center",
    flex: 1,
    mt: "85px",
    //mr: "20px",
  },
  leftLine: {
    width: "100%",
    height: "3px",
    backgroundColor: "#363D45",
  },
  leftLineWhite: {
    width: "100%",
    height: "3px",
    backgroundColor: "white",
    display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" },
  },
  halfCircle: {
    width: "10px",
    borderRadius: "10px 0 0 10px",
    height: "20px",
    backgroundColor: "#363D45",
  },
  circle: {
    width: "20px",
    minWidth: "20px",
    borderRadius: "100px",
    height: "20px",
    backgroundColor: "white",
    mt: "-8px",
    display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" },
  },
  containerImageRow: {
    width: "100%",
    maxWidth: "100%",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  imageAndIcon: {
    height: "242px;",
    borderRadius: "15px",
    mb: "30px",
    maxWidth: { xs: 360, sm: 360, md: 270, lg: 360, xl: 360 },
    width: { xs: "100%", sm: 550, md: 700, lg: 320, xl: 360 },
    margin: "10px auto",
  },
  image: {
    width: "100%",
    objectFit: "contain",
  },
  iconAndText: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    backgroundColor: "#363D45",
    mt: "-7px",
    padding: "14px 20px",
    borderRadius: "0 0 15px 15px",
  },
  textIcon: {
    color: "white",
    fontSize: "11px",
    mr: "10px",
  },
  imageIconText: {
    fontSize: "17px",
  },
  buttonLight: {
    textTransform: "none",
    fontWeight: "bold",
    fontSize: { xs: 14, sm: 14, md: 17, lg: 17, xl: 17 },
    height: "52px",
    borderRadius: "100px",
    background: "#346EA9",
    color: "white",
    pl: "20px",
    pr: "20px",
    "&:hover": {
      backgroundColor: "white",
      transition: ".4s ease",
      color: "#082e55",
      borderColor: "#082e55",
    },
  },
  headerBlue: {
    background: "radial-gradient(#05A5DF, #1F4880)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    //height: "100%",
    width: "100%",
    paddingY: "100px",
    //height: 700,
  },
  itemHeader: {
    backgroundImage: `url(${cloud})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    height: 450,
    width: 450,
    mx: "40px",
    borderRadius: "50%",
  },
  iconCheck: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    width: 20,
    height: 20,
    backgroundColor: "white",
    //mt: "8px",
    mr: "10px",
    borderRadius: "50px",
  },
  lastSection: {
    border: "2px solid white",
    p: "20px 30px 40px 35px",
    borderRadius: "20px",
    width: "100%",
  },
}

const HomePage = () => {
  const navigate = useNavigate()
  const { t } = useLanguage()
  const settings: any = JSON.parse(localStorage.getItem("settings") || "")

  return (
    <GridItem>
      <GridContainer sx={[styles.containerFull, styles.backgroundBlueGradient]} style={{ paddingBottom: "100px", paddingTop: "80px" }}>
        <GridContainer sx={[styles.container1070_3]}>
          <GridItem sx={[styles.gap, styles.whiteLine]}>
            {/* <GridItem sx={styles.leftLineWhite}></GridItem>
            <GridItem sx={styles.circle}></GridItem> */}
          </GridItem>

          <GridContainer sx={styles.container}>
            <GridItem sx={styles.item60}>
              <Typography variant="body2" sx={styles.whiteBoldText}>
                {t("einfachSmart1")}
              </Typography>
              <Typography variant="body2" sx={styles.sectionHeading}>
                {t("einfachSmart2")}
              </Typography>
              <Typography variant="body2" sx={styles.subTitle}>
                {t("datenakrobatik")}
              </Typography>
              <Typography variant="body2" sx={styles.text}>
                {t("mitSiot")}
              </Typography>
              <Button variant="outlined" onClick={() => navigate("/meeting")} sx={styles.button}>
                {t("buttonHeader")}
              </Button>
              <GridItem sx={{ marginTop: "30px" }}>
                <Button component={MuiLink} underline="none" href={settings.IOTOOLS_BP_FRONT_URL} sx={styles.button1}>
                  {t("trySiotNow")}
                </Button>
              </GridItem>
            </GridItem>
            <GridItem sx={styles.item40}>
              <GridItem sx={styles.item2}>
                <img
                  src={cloud}
                  alt="City Cloud"
                  loading="lazy"
                  style={{ objectFit: "contain", maxWidth: "100%", maxHeight: "100%", borderRadius: "50%" }}
                />
              </GridItem>
            </GridItem>
          </GridContainer>

          <GridItem sx={styles.gap}></GridItem>
        </GridContainer>
      </GridContainer>
      <GridItem sx={styles.partners}>
        <Carousel logos={logos} />
      </GridItem>

      <GridContainer sx={[styles.containerFull, styles.backgroundGray]} style={{ paddingTop: "100px" }}>
        <GridContainer sx={[styles.container1070]} style={{ gap: "6%", alignItems: "start" }}>
          <GridItem sx={styles.item50}>
            <Typography variant="body2" sx={styles.grayBoldText}>
              {t("dieIoTvolution1")}{" "}
              <Box component="span" m="{1}" style={{ color: "#444444" }}>
                IoT
              </Box>
              {t("dieIoTvolution2")}
            </Typography>
            <Typography variant="body2" sx={styles.sectionHeading}>
              {t("programmierenWar")}
            </Typography>
          </GridItem>
          <GridItem sx={styles.item50}></GridItem>
          <GridItem sx={styles.item50}>
            <Typography variant="body2" sx={styles.text} style={{ marginBottom: "20px" }}>
              {t("inEinemMarkt")}
            </Typography>
            <Typography variant="body2" sx={styles.text} style={{ marginBottom: "20px" }}>
              {t("alsHersteller")}
            </Typography>
          </GridItem>
          <GridItem sx={styles.item50}>
            <Typography variant="body2" sx={styles.text} style={{ marginBottom: "20px" }}>
              {t("nebenEiner")}
            </Typography>
            <Typography variant="body2" sx={styles.text} style={{ marginBottom: "20px" }}>
              {t("dabeiFurhen")}
            </Typography>
          </GridItem>
          <Button variant="outlined" onClick={() => navigate("/meeting")} sx={styles.button} style={{ marginBottom: "50px" }}>
            {t("button2")}
          </Button>
        </GridContainer>
      </GridContainer>

      <GridContainer sx={{ width: "100%", height: "310px" }}>
        <GridItem sx={styles.picture2}></GridItem>
      </GridContainer>

      <GridContainer id={"scenarios"} sx={[styles.containerFull, styles.backgroundGray]} style={{ paddingTop: "100px", paddingBottom: "100px" }}>
        <GridContainer sx={[styles.container1070]}>
          <Typography variant="body2" sx={styles.sectionHeading}>
            {t("dasKommtIhnen")}
          </Typography>

          <GridItem sx={styles.solutionProblemRow}>
            <Typography variant="body2" sx={[styles.textSolution, styles.whiteBoldText]}>
              {t("situation1")}
            </Typography>
            <GridItem sx={styles.shape}>
              <img src={shape} alt="Siot Logo" loading="lazy" />
            </GridItem>
            <Typography variant="body2" sx={[styles.text, styles.textProblem]}>
              {t("problem1")}
            </Typography>
          </GridItem>
          <GridItem sx={styles.solutionProblemRow}>
            <Typography variant="body2" sx={[styles.textSolution, styles.whiteBoldText]}>
              {t("situation2")}
            </Typography>
            <GridItem sx={styles.shape}>
              <img src={shape} alt="Siot Logo" loading="lazy" />
            </GridItem>
            <Typography variant="body2" sx={[styles.text, styles.textProblem]}>
              {t("problem2")}
            </Typography>
          </GridItem>
          <GridItem sx={styles.solutionProblemRow}>
            <Typography variant="body2" sx={[styles.textSolution, styles.whiteBoldText]}>
              {t("situation3")}
            </Typography>
            <GridItem sx={styles.shape}>
              <img src={shape} alt="Siot Logo" loading="lazy" />
            </GridItem>
            <Typography variant="body2" sx={[styles.text, styles.textProblem]}>
              {t("problem3")}
            </Typography>
          </GridItem>
        </GridContainer>
      </GridContainer>

      <GridContainer sx={[styles.containerFull, styles.container1070]} style={{ flexDirection: "row", marginTop: "-137px", width: "100%" }}>
        <GridItem sx={styles.highlited}>
          {t("sprechenWirUberDieLosung")}
          <GridItem
            sx={{
              marginLeft: { xs: "0", sm: "0px", md: "30px", lg: "30px", xl: "30px" },
              width: { xs: "140px", sm: "170px", md: "220px", lg: "220px", xl: "220px" },
            }}
          >
            <img src={`${siotLogoWhite}`} alt="Siot Logo" loading="lazy" style={{ width: "inherit" }} />
          </GridItem>
        </GridItem>
      </GridContainer>

      <GridContainer sx={[styles.backgroundBlack]}>
        <GridContainer id="success-components" sx={[styles.containerFull]} style={{ paddingTop: "170px" }}>
          <GridContainer sx={[styles.container1070]} style={{ flexDirection: "column", alignItems: "start" }}>
            <GridItem style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="body2" sx={styles.blueBoldText}>
                {t("dasFehlende")}
              </Typography>
              <Typography variant="body2" sx={styles.sectionHeading}>
                {t("siotTheSmartIntegrationOfThings")}
              </Typography>
            </GridItem>
            <GridItem sx={{ display: "flex" }}>
              <GridItem sx={styles.item50}>
                <Typography variant="body2" sx={styles.text}>
                  {t("alsPlatrormAsAService")}
                </Typography>
              </GridItem>
              <GridItem sx={styles.item50}></GridItem>
            </GridItem>
          </GridContainer>
        </GridContainer>

        <GridContainer sx={[styles.containerFull]}>
          <GridContainer sx={[styles.container1070]}>
            <GridItem sx={styles.iconAndHeading}>
              <GridItem sx={styles.headingIcon}>
                <SquareStackIcon width={36} height={36} />
              </GridItem>
              <Typography variant="body2" sx={styles.headingText}>
                {t("bausteineFurIhrenErfolg")}
              </Typography>
            </GridItem>
          </GridContainer>
        </GridContainer>

        <GridContainer sx={[styles.containerFull]}>
          <GridContainer sx={[styles.container1070_3]}>
            <GridItem sx={styles.gap}>
              <GridItem sx={styles.leftLine}></GridItem>
              <GridItem sx={styles.halfCircle}></GridItem>
            </GridItem>
            <GridItem sx={styles.iconsHolder}>
              <Typography sx={styles.iconHolderHeading}>{t("produkt")}</Typography>
              <ProductIcons />
            </GridItem>
            <GridItem sx={styles.gap}></GridItem>
          </GridContainer>
        </GridContainer>

        <GridContainer sx={[styles.containerFull]}>
          <GridContainer sx={[styles.container1070_3]}>
            <GridItem sx={styles.gap}>
              <GridItem sx={styles.leftLine}></GridItem>
              <GridItem sx={styles.halfCircle}></GridItem>
            </GridItem>
            <GridItem sx={styles.iconsHolder}>
              <Typography sx={styles.iconHolderHeading}>{t("services")}</Typography>
              <ServicesIcons />
            </GridItem>
            <GridItem sx={styles.gap}></GridItem>
          </GridContainer>
        </GridContainer>

        <GridContainer sx={[styles.containerFull]}>
          <GridContainer sx={[styles.container1070]}>
            <GridItem sx={styles.iconAndHeading}>
              <GridItem sx={styles.headingIcon}>
                <BuildingLibraryIcon width={36} height={36} />
              </GridItem>
              <Typography variant="body2" sx={styles.headingText}>
                {t("branchen")}
              </Typography>
            </GridItem>
          </GridContainer>
        </GridContainer>

        <GridContainer sx={[styles.containerFull]}>
          <GridContainer sx={[styles.container1070]}>
            <GridContainer sx={[styles.containerImageRow]}>
              <GridItem sx={styles.imageAndIcon}>
                <GridItem sx={styles.image}>
                  <img
                    src={`${industrialAutomationImage}`}
                    alt="Industrial Automation"
                    // loading="lazy"
                    style={{ width: "100%", borderTopLeftRadius: "15px", borderTopRightRadius: "15px" }}
                  />
                </GridItem>
                <GridItem sx={styles.iconAndText}>
                  <GridItem sx={styles.textIcon}>
                    <CogIcon width={24} height={24} />
                  </GridItem>
                  <Typography variant="body2" sx={styles.imageIconText}>
                    {t("industrialAutomation")}
                  </Typography>
                </GridItem>
              </GridItem>

              <GridItem sx={styles.imageAndIcon}>
                <GridItem sx={styles.image}>
                  <img
                    src={`${smartBuildingImage}`}
                    alt="Smart Building"
                    // loading="lazy"
                    style={{ width: "100%", borderTopLeftRadius: "15px", borderTopRightRadius: "15px" }}
                  />
                </GridItem>
                <GridItem sx={styles.iconAndText}>
                  <GridItem sx={styles.textIcon}>
                    <HomeModernIcon width={24} height={24} />
                  </GridItem>
                  <Typography variant="body2" sx={styles.imageIconText}>
                    {t("smartBuilding")}
                  </Typography>
                </GridItem>
              </GridItem>

              <GridItem sx={styles.imageAndIcon}>
                <GridItem sx={styles.image}>
                  <img
                    src={`${smartEnergyImage}`}
                    alt="Smart Energy"
                    // loading="lazy"
                    style={{ width: "100%", borderTopLeftRadius: "15px", borderTopRightRadius: "15px" }}
                  />
                </GridItem>
                <GridItem sx={styles.iconAndText}>
                  <GridItem sx={styles.textIcon}>
                    <BoltIcon width={24} height={24} />
                  </GridItem>
                  <Typography variant="body2" sx={styles.imageIconText}>
                    {t("smartEnergy")}
                  </Typography>
                </GridItem>
              </GridItem>

              <GridItem sx={styles.imageAndIcon}>
                <GridItem sx={styles.image}>
                  <img
                    src={`${smartTransportationImage}`}
                    alt="Smart Transportation"
                    // loading="lazy"
                    style={{ width: "100%", borderTopLeftRadius: "15px", borderTopRightRadius: "15px" }}
                  />
                </GridItem>
                <GridItem sx={styles.iconAndText}>
                  <GridItem sx={styles.textIcon}>
                    <TruckIcon width={24} height={24} />
                  </GridItem>
                  <Typography variant="body2" sx={styles.imageIconText}>
                    {t("smartTransportation")}
                  </Typography>
                </GridItem>
              </GridItem>

              <GridItem sx={styles.imageAndIcon}>
                <GridItem sx={styles.image}>
                  <img
                    src={`${smartCityImage}`}
                    alt="Smart City"
                    // loading="lazy"
                    style={{ width: "100%", borderTopLeftRadius: "15px", borderTopRightRadius: "15px" }}
                  />
                </GridItem>
                <GridItem sx={styles.iconAndText}>
                  <GridItem sx={styles.textIcon}>
                    <BuildingOfficeIcon width={24} height={24} />
                  </GridItem>
                  <Typography variant="body2" sx={styles.imageIconText}>
                    {t("smartCity")}
                  </Typography>
                </GridItem>
              </GridItem>

              <GridItem sx={styles.imageAndIcon}>
                <GridItem sx={styles.image}>
                  <img
                    src={`${assetTrackingImage}`}
                    alt="Asset Tracking"
                    // loading="lazy"
                    style={{ width: "100%", borderTopLeftRadius: "15px", borderTopRightRadius: "15px" }}
                  />
                </GridItem>
                <GridItem sx={styles.iconAndText}>
                  <GridItem sx={styles.textIcon}>
                    <ViewFinderCircleIcon width={24} height={24} />
                  </GridItem>
                  <Typography variant="body2" sx={styles.imageIconText} id="your-benefits">
                    {t("assetTracking")}
                  </Typography>
                </GridItem>
              </GridItem>
            </GridContainer>
          </GridContainer>
        </GridContainer>

        <GridContainer sx={[styles.containerFull]} style={{ paddingBottom: "100px" }}>
          <GridContainer sx={[styles.container1070]}>
            <GridItem sx={styles.item60}>
              <GridItem sx={styles.iconAndHeading}>
                <GridItem sx={styles.headingIcon}>
                  <SparklesIcon width={36} height={36} />
                </GridItem>
                <Typography variant="body2" sx={styles.headingText}>
                  {t("ihreVorteile")}
                </Typography>
              </GridItem>
              <AdvantagesAccordion />
              <Button onClick={() => navigate("/meeting")} sx={styles.buttonLight} style={{ marginTop: "50px" }}>
                {t("dasMultifunktions")}
              </Button>
            </GridItem>

            <GridItem sx={styles.item40}>
              <GridItem sx={styles.item2}>
                <img
                  src={cityCloudImage}
                  alt="City Cloud"
                  loading="lazy"
                  style={{ objectFit: "contain", maxWidth: "100%", maxHeight: "100%", borderRadius: "50%" }}
                />
              </GridItem>
            </GridItem>
          </GridContainer>
        </GridContainer>
      </GridContainer>

      <GridContainer sx={[styles.containerFull, styles.backgroundGray]} style={{ paddingTop: "100px" }}>
        <GridContainer sx={[styles.container1070]} style={{ gap: "6%", alignItems: "start" }}>
          <GridItem sx={styles.item50}>
            <Typography variant="body2" sx={styles.grayBoldText}>
              {t("bereitFurIhre")}
            </Typography>
            <Typography variant="body2" sx={styles.sectionHeading}>
              {t("ihreVersionUnserKonnen")}
            </Typography>
          </GridItem>
          <GridItem sx={styles.item50}></GridItem>
          <GridItem sx={styles.item50}>
            <Typography variant="body2" sx={styles.text} style={{ marginBottom: "20px" }}>
              {t("sieHabenErsteIdeen")}
            </Typography>
            <Typography variant="body2" sx={styles.text} style={{ marginBottom: "20px" }}>
              {t("dieseChallengeNehmen")}
            </Typography>
          </GridItem>
          <GridItem sx={styles.item50}>
            <Typography variant="body2" sx={styles.text} style={{ marginBottom: "20px" }}>
              {t("wirEntwickelnMit")}
            </Typography>
            <Typography variant="body2" sx={styles.text} style={{ marginBottom: "20px" }}>
              {t("dieseStellenWirIhnen")}
            </Typography>
          </GridItem>
          <Button variant="outlined" onClick={() => navigate("/meeting")} sx={styles.button} style={{ marginBottom: "50px" }}>
            {t("jetzKostenlosen")}
          </Button>
        </GridContainer>
      </GridContainer>

      <GridContainer sx={[styles.containerFull, styles.backgrounBlue]} style={{ paddingTop: "100px" }}>
        <GridContainer sx={[styles.container1070]} style={{ gap: "6%", alignItems: "start" }}>
          <GridItem sx={styles.item50} style={{ marginBottom: "30px" }}>
            <Typography variant="body2" sx={styles.whiteBoldText}>
              {t("wieKonnenWir")}
            </Typography>
            <Typography variant="body1" sx={styles.sectionHeading}>
              {t("erkundenWirIhreIotPotenziale")}
            </Typography>
            <GridItem sx={{ display: "flex", mb: "10px", mt: "20px", flexWrap: "wrap" }}>
              <GridContainer sx={{ mr: "15px", width: "150px" }}>
                <GridItem sx={styles.iconCheck}>
                  <IconCheck />
                </GridItem>
                <Typography variant="body2" sx={styles.textSmall}>
                  {t("kostenfrei")}
                </Typography>
              </GridContainer>
              <GridContainer sx={{ mr: "15px", width: "150px" }}>
                <GridItem sx={styles.iconCheck}>
                  <IconCheck />
                </GridItem>
                <Typography variant="body2" sx={styles.textSmall}>
                  {t("unverbindlich")}
                </Typography>
              </GridContainer>
            </GridItem>
            <GridItem sx={{ display: "flex", mb: "10px", mt: "20px", flexWrap: "wrap" }}>
              <GridContainer sx={{ mr: "15px", width: "150px" }}>
                <GridItem sx={styles.iconCheck}>
                  <IconCheck />
                </GridItem>
                <Typography variant="body2" sx={styles.textSmall}>
                  {t("visionär")}
                </Typography>
              </GridContainer>
              <GridContainer sx={{ mr: "15px", width: "150px" }}>
                <GridItem sx={styles.iconCheck}>
                  <IconCheck />
                </GridItem>
                <Typography variant="body2" sx={styles.textSmall}>
                  {t("vertraulich")}
                </Typography>
              </GridContainer>
            </GridItem>
          </GridItem>
          <GridItem sx={styles.item50} style={{ marginBottom: "30px" }}>
            <GridItem sx={styles.itemLastSection}>
              <GridItem sx={styles.lastSection}>
                <Typography variant="body1" sx={styles.title2}>
                  {t("buchenSieIhrenTermin")}
                </Typography>
                <Button variant="outlined" onClick={() => navigate("/meeting")} sx={styles.buttonLight}>
                  {t("zumCalender")}
                </Button>
              </GridItem>
            </GridItem>
          </GridItem>
        </GridContainer>
      </GridContainer>
    </GridItem>
  )
}
export default HomePage
