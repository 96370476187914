import { blue, blueGrey } from "@mui/material/colors"
import { createTheme } from "@mui/material/styles"

export const AppTheme = createTheme({
  palette: {
    background: {
      default: "white",
    },
    primary: blue,
    secondary: blueGrey,
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "14px",
          fontWeight: "bold",
          backgroundColor: "#1aae9f",
          padding: "15px 10px",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "15px",
          border: "1px solid #6e6e6e",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          padding: "2px 16px",
        },
        message: {
          fontSize: "14px",
        },
      },
    },
  },
})
