import GridItem from "../../basic/grid/GridItem"
import GridContainer from "../../basic/grid/GridContainer"
import { routesAppBar } from "../navigation/Routes"
import { Button, List, Link as MuiLink, ListItemButton, Typography } from "@mui/material"
import { Link, useLocation, useNavigate } from "react-router-dom"
import SupportMenu from "../../basic/menu/SupportMenu"
import { ReactComponent as SiotLogo } from "../../../assets/images/siotLogo.svg"
import { HashLink } from "react-router-hash-link"
import LanguageMenu from "../../basic/menu/LanguageMenu"
import { useLanguage } from "../../../languageContext"
import TopMenu from "../../basic/menu/TopMenu"

interface Route {
  name: string
  route?: string
  subitems?: Subitem[]
}

interface Subitem {
  name: string
  route: string
}

const styles = {
  links: {
    gap: "10px",
    height: "75px",
    display: { xs: "none", sm: "none", md: "none", lg: "flex", xl: "flex" },
    justifyContent: "center",
    alignItems: "center",
    marginX: "auto",
    color: "#666666",
  },
  linkHolderActive: {
    textAlign: "center",
    height: "34px",
    mt: "6px",
    backgroundColor: "#e6f2ff",
    borderRadius: "25px",
    padding: "5px 15px",
    "&:hover": {
      backgroundColor: "#e6f2ff",
      color: "black",
    },
  },
  linkHolder: {
    textAlign: "center",
    height: "34px",
    mt: "6px",
    borderRadius: "25px",
    padding: "5px 15px",
    "&:hover": {
      backgroundColor: "#e6f2ff",
      color: "black",
    },
  },
  iconHolderActive1: {
    borderRadius: "100%",
    width: "42px",
    height: "42px",
    display: "flex",
    mr: "22px",
    ml: "auto",
    "&:hover": {
      background: "#e6f2ff",
    },
    cursor: "pointer",
  },
  icon: {
    width: "42px",
    height: "42px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#666666",
  },
  menu: {
    mt: "6px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#666666",
    padding: "5px 0px",
  },
  icons: {
    display: "flex",
    "@media (max-width: 420px)": {
      display: "none",
    },
    justifyContent: "center",
    alignItems: "center",
    //ml: "auto",
    height: "75px",
  },
  icons1: {
    display: "none",
    "@media (max-width: 420px)": {
      display: "flex",
    },
    justifyContent: "flex-end",
    alignItems: "center",
    mt: "5px",
    mr: "10px",
  },
  button: {
    display: {
      xs: "flex",
      sm: "flex",
      md: "flex",
      lg: "flex",
      xl: "flex",
    },
    "@media (max-width: 420px)": {
      display: "none",
    },
    textTransform: "none",
    fontSize: { xs: "13px", sm: "14px", md: "14px", lg: "14px", xl: "14px" },
    fontWeight: "bold",
    height: "33px",
    ml: "10px",
    px: "15px",
    mr: { xs: "11px", sm: "20px", md: "25px", lg: "25px", xl: "25px" },
    borderRadius: "100px",
    backgroundColor: "#346ea9",
    color: "white",
    border: "1px solid #346ea9",
    "&:hover": {
      backgroundColor: "white",
      transition: ".4s ease",
      color: "#346ea9",
      border: "1px solid #346ea9",
    },
  },
  smallResolutionButton: {
    display: {
      xs: "none",
      sm: "none",
      md: "none",
      lg: "none",
      xl: "none",
    },
    "@media (max-width: 420px)": {
      display: "flex",
    },
    textTransform: "none",
    fontSize: { xs: "13px", sm: "14px", md: "14px", lg: "14px", xl: "14px" },
    fontWeight: "bold",
    height: "33px",
    px: "15px",
    mr: "15px",
    borderRadius: "100px",
    backgroundColor: "#346ea9",
    color: "white",
    border: "1px solid #346ea9",
    "&:hover": {
      backgroundColor: "white",
      transition: ".4s ease",
      color: "#346ea9",
      border: "1px solid #346ea9",
    },
  },
  flag: {
    width: 10,
    mr: 2,
    cursor: "pointer",
  },
  iconButton: {
    mr: "auto",
    ml: "10px",
  },
}

const AppBar = () => {
  const { t } = useLanguage()
  const location = useLocation()
  const navigate = useNavigate()
  const settings: any = JSON.parse(localStorage.getItem("settings") || "")

  const isActiveRoute = (route: string) => {
    if (route.substring(5) === location.hash) {
      return true
    } else if (route.substring(5) === location.hash) {
      return true
    } else if (route.substring(5) === location.hash) {
      return true
    } else {
      return location.pathname === route
    }
  }

  return (
    <GridItem
      sx={{
        height: "75px",
        "@media (max-width: 420px)": {
          height: "100px",
        },
        width: { xs: "90%", sm: "85%", md: "80%", lg: "80%", xl: "80%" },
        maxWidth: { xs: "90%", sm: "85%", md: "80%", lg: "80%", xl: 1300 },
        marginX: "auto",
      }}
    >
      <GridContainer style={{ justifyContent: "space-between" }}>
        <GridItem
          sx={{
            width: { xs: "110px", sm: "150px", md: "150px", lg: "155px", xl: "155px" },
            cursor: "pointer",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            "@media (max-width: 420px)": {
              mt: "12px",
            },
          }}
          onClick={() => navigate("/home")}
        >
          <SiotLogo />
          <Typography
            variant="body2"
            sx={{ letterSpacing: "0.7px", fontSize: { xs: "7px", sm: "11px", md: "11px", lg: "11px", xl: "11px" }, color: "black" }}
          >
            powered by Appmodule AG
          </Typography>
        </GridItem>
        <List sx={styles.links} component="div" disablePadding>
          {routesAppBar.map((route: Route, index: number) =>
            route.route !== undefined ? (
              route.route === "/home#scenarios" || route.route === "/home#success-components" || route.route === "/home#your-benefits" ? (
                <ListItemButton
                  component={HashLink}
                  key={index}
                  smooth
                  to={route.route}
                  sx={isActiveRoute(route.route) ? styles.linkHolderActive : styles.linkHolder}
                >
                  {t(route.name)}
                </ListItemButton>
              ) : (
                <ListItemButton
                  component={Link}
                  to={route.route}
                  key={index}
                  sx={isActiveRoute(route.route) ? styles.linkHolderActive : styles.linkHolder}
                >
                  {t(route.name)}
                </ListItemButton>
              )
            ) : (
              <GridItem sx={styles.menu} key={index}>
                <SupportMenu subitemRoutes={route.subitems} />
              </GridItem>
            )
          )}
        </List>
        <TopMenu />
        <GridItem sx={styles.icons}>
          <Button component={MuiLink} underline="none" href={settings.IOTOOLS_BP_FRONT_URL} sx={styles.button}>
            {t("trySiotNow")}
          </Button>
          <LanguageMenu />
        </GridItem>
      </GridContainer>
      <GridItem sx={styles.icons1}>
        <Button component={MuiLink} underline="none" href={settings.IOTOOLS_BP_FRONT_URL} sx={styles.smallResolutionButton}>
          {t("trySiotNow")}
        </Button>
        <LanguageMenu />
      </GridItem>
    </GridItem>
  )
}
export default AppBar
