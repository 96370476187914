import React from "react"
import GridItem from "../../components/basic/grid/GridItem"
import { Box, Typography } from "@mui/material"
import { useLanguage } from "../../languageContext"
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import TextTable1 from '../../components/advanced/TextTable1';
import TextTable2 from '../../components/advanced/TextTable2';
import GridContainer from '../../components/basic/grid/GridContainer';

const styles = {
  grid: {
    width: "100%",
    p: 3,
  },
  container1070: {
    width: { xs: "100%", sm: "85%", md: "80%", lg: "80%", xl: "80%" },
    maxWidth: { xs: "100%", sm: "85%", md: "80%", lg: "80%", xl: 1300 },
    // pl: { xs: "25px", sm: "0", md: "0", lg: "0", xl: "0" },
    // pr: { xs: "25px", sm: "0", md: "0", lg: "0", xl: "0" },
    margin: "0 auto",
    mb: "15px",
    flex: 1,
    flexWrap: "wrap",
    justifyContent: "left",
    alignItems: "center",
  },
  sectionHeading: {
    fontSize: { xs: 24, sm: 24, md: 32, lg: 38, xl: 38 },
    fontWeight: "bold",
    mb: "30px",
  },
  subTitle2: {
    fontWeight: "bold",
    fontSize: "20px",
    marginBottom: "10px",
    color: "#868686",
  },
  text: {
    fontSize: { xs: 13, sm: 16, md: 19, lg: 19, xl: 19 },
    lineHeight: 1.5,
    marginBottom: "20px"
  },
  textNoMargin: {
    fontSize: { xs: 13, sm: 16, md: 19, lg: 19, xl: 19 },
    lineHeight: { xs: 1.5, sm: 1.5, md: 1.5, lg: 1.5, xl: 1.5 },
  },
  textBold: {
    fontSize: { xs: 13, sm: 16, md: 19, lg: 19, xl: 19 },
    lineHeight: { xs: 1.5, sm: 1.5, md: 2, lg: 2, xl: 2 },
    fontWeight: "bold",
    marginTop: "40px",
    marginBottom: "20px"
  },
  list: {
    fontSize: { xs: 13, sm: 15, md: 18, lg: 18, xl: 18 },
    mb: "20px"
  },
  list1: {
    fontSize: { xs: 13, sm: 15, md: 18, lg: 18, xl: 18 },
  }

}
const DataProtectionPage = () => {
  const { t } = useLanguage()

  return (
    <GridContainer sx={styles.grid}>
      {/* <GridItem sx={{ pt: "50px" }}> */}
      <GridItem sx={styles.container1070}>
        <Typography variant="body2" sx={styles.sectionHeading}>
          {t("datenschutz")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("dieAppmoduleAGFreut")}
        </Typography>
        <Typography variant="body2" sx={styles.textBold}>
          {t("privacy1")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy1_1")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy1_2")}
        </Typography>
        <Typography variant="body2" sx={styles.textNoMargin}>
          {t("privacyAddress1")}
        </Typography>
        <Typography variant="body2" sx={styles.textNoMargin}>
          {t("privacyAddress2")}
        </Typography>
        <Typography variant="body2" sx={styles.textNoMargin}>
          {t("privacyAddress3")}
        </Typography>
        <Typography variant="body2" sx={styles.textNoMargin}>
          {t("privacyAddress4")}
        </Typography>
        <Typography variant="body2" sx={styles.textNoMargin}>
          {t("privacyAddress5")}
        </Typography>
        <Typography variant="body2" sx={styles.textNoMargin}>
          E-Mail: <Link href="mailto:info@appmodule.net">info@appmodule.net</Link>
        </Typography>
        <Typography variant="body2" sx={styles.text} style={{ marginTop: "40px" }}>
          {t("privacy1_3")}
        </Typography>
        <Typography variant="body2" sx={styles.textNoMargin}>
          {t("privacyAddress1")}
        </Typography>
        <Typography variant="body2" sx={styles.textNoMargin}>
          {t("privacyAddress6")}
        </Typography>
        <Typography variant="body2" sx={styles.textNoMargin}>
          {t("privacyAddress2")}
        </Typography>
        <Typography variant="body2" sx={styles.textNoMargin}>
          {t("privacyAddress3")}
        </Typography>
        <Typography variant="body2" sx={styles.textNoMargin}>
          {t("privacyAddress4")}
        </Typography>
        <Typography variant="body2" sx={styles.textNoMargin}>
          E-Mail: <Link href="mailto:privacy@appmodule.net">privacy@appmodule.net</Link>
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy1_4")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy1_5")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy1_6")}
        </Typography>

        <Typography variant="body2" sx={styles.textBold}>
          {t("privacy2")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy2_1")}
        </Typography>

        <List sx={styles.list}>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon style={{ fontSize: "15px" }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: { xs: 13, sm: 16, md: 19, lg: 19, xl: 19 } }} primary={t("privacy2List1")} />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon style={{ fontSize: "15px" }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: { xs: 13, sm: 16, md: 19, lg: 19, xl: 19 } }} primary={t("privacy2List2")} />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon style={{ fontSize: "15px" }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: { xs: 13, sm: 16, md: 19, lg: 19, xl: 19 } }} primary={t("privacy2List3")} />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon style={{ fontSize: "15px" }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: { xs: 13, sm: 16, md: 19, lg: 19, xl: 19 } }} primary={t("privacy2List4")} />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon style={{ fontSize: "15px" }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: { xs: 13, sm: 16, md: 19, lg: 19, xl: 19 } }} primary={t("privacy2List5")} />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon style={{ fontSize: "15px" }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: { xs: 13, sm: 16, md: 19, lg: 19, xl: 19 } }} primary={t("privacy2List6")} />
          </ListItem>
        </List>

        <Typography variant="body2" sx={styles.text}>
          {t("privacy2_2")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy2_3")}
        </Typography>
        <Typography variant="body2" sx={styles.textBold}>
          {t("privacy2_4")}
        </Typography>
        <Typography variant="body2" sx={styles.textNoMargin}>
          {t("privacyAddress7")}
        </Typography>
        <Typography variant="body2" sx={styles.textNoMargin}>
          CH – 3003 Bern
        </Typography>
        <Typography variant="body2" sx={styles.textNoMargin}>
          {t("privacyAddress4")}
        </Typography>
        <Typography variant="body2" sx={styles.textNoMargin}>
          {t("privacyAddress8")}
        </Typography>
        <Typography variant="body2" sx={styles.textNoMargin}>
          {t("privacyAddress9")}
        </Typography>
        <Typography variant="body2" sx={styles.textNoMargin}>
          E-Mail: <Link href="mailto:info@edoeb.admin.ch">info@edoeb.admin.ch</Link>
        </Typography>

        <Typography variant="body2" sx={styles.textBold}>
          {t("privacy3")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy3_1")}
        </Typography>

        <List sx={styles.list}>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon style={{ fontSize: "15px" }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: { xs: 13, sm: 16, md: 19, lg: 19, xl: 19 } }} primary={t("privacy3List1")} />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon style={{ fontSize: "15px" }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: { xs: 13, sm: 16, md: 19, lg: 19, xl: 19 } }} primary={t("privacy3List2")} />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon style={{ fontSize: "15px" }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: { xs: 13, sm: 16, md: 19, lg: 19, xl: 19 } }} primary={t("privacy3List11")} />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon style={{ fontSize: "15px" }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: { xs: 13, sm: 16, md: 19, lg: 19, xl: 19 } }} primary={t("privacy3List3")} />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon style={{ fontSize: "15px" }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: { xs: 13, sm: 16, md: 19, lg: 19, xl: 19 } }} primary={t("privacy3List4")} />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon style={{ fontSize: "15px" }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: { xs: 13, sm: 16, md: 19, lg: 19, xl: 19 } }} primary={t("privacy3List5")} />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon style={{ fontSize: "15px" }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: { xs: 13, sm: 16, md: 19, lg: 19, xl: 19 } }} primary={t("privacy3List6")} />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon style={{ fontSize: "15px" }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: { xs: 13, sm: 16, md: 19, lg: 19, xl: 19 } }} primary={t("privacy3List7")} />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon style={{ fontSize: "15px" }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: { xs: 13, sm: 16, md: 19, lg: 19, xl: 19 } }} primary={t("privacy3List8")} />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon style={{ fontSize: "15px" }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: { xs: 13, sm: 16, md: 19, lg: 19, xl: 19 } }} primary={t("privacy3List9")} />
          </ListItem>
        </List>

        <Typography variant="body2" sx={styles.text}>
          {t("privacy3_2")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy3_3")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy3_4")}
        </Typography>

        <List sx={styles.list}>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon style={{ fontSize: "15px" }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: { xs: 13, sm: 16, md: 19, lg: 19, xl: 19 } }} primary={t("privacy3List10")} />
          </ListItem>
        </List>

        <Typography variant="body2" sx={styles.text}>
          {t("privacy3_5")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy3_6")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy3_7")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy3_8")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy3_9")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy3_10")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy3_11")}
        </Typography>
        <TextTable1 />
        <Typography variant="body2" sx={styles.text}>
          {t("privacy3_12")}
        </Typography>
        <TextTable2 />

        <Typography variant="body2" sx={styles.textBold}>
          {t("privacy4")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy4_1")}
        </Typography>

        <Typography variant="body2" sx={styles.textBold}>
          {t("privacy5")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy5_1")}
        </Typography>

        <List sx={styles.list}>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon style={{ fontSize: "15px" }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: { xs: 13, sm: 16, md: 19, lg: 19, xl: 19 } }} primary={t("privacy5List1")} />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon style={{ fontSize: "15px" }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: { xs: 13, sm: 16, md: 19, lg: 19, xl: 19 } }} primary={t("privacy5List2")} />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon style={{ fontSize: "15px" }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: { xs: 13, sm: 16, md: 19, lg: 19, xl: 19 } }} primary={t("privacy5List3")} />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon style={{ fontSize: "15px" }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: { xs: 13, sm: 16, md: 19, lg: 19, xl: 19 } }} primary={t("privacy5List4")} />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon style={{ fontSize: "15px" }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: { xs: 13, sm: 16, md: 19, lg: 19, xl: 19 } }} primary={t("privacy5List5")} />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon style={{ fontSize: "15px" }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: { xs: 13, sm: 16, md: 19, lg: 19, xl: 19 } }} primary={t("privacy5List6")} />
          </ListItem>
        </List>

        <Typography variant="body2" sx={styles.text}>
          {t("privacy5_2")}
        </Typography>

        <Typography variant="body2" sx={styles.textBold}>
          {t("privacy6")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy6_1")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy6_2")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy6_3")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy6_4")}
        </Typography>

        <Typography variant="body2" sx={styles.textBold}>
          {t("privacy7")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy7_1")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy7_2")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy7_3")} <Link href="tools.google.com/dlpage/gaoptout">tools.google.com/dlpage/gaoptout</Link>.
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy7_4")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy7_5")} <Link href="http://www.google.com/analytics/terms/de.html">http://www.google.com/analytics/terms/de.html</Link>, {t("privacy7_6")} <Link href="http://www.google.com/intl/de/analytics/learn/privacy.html">http://www.google.com/intl/de/analytics/learn/privacy.html</Link>, {t("privacy7_7")} <Link href="http://www.google.de/intl/de/policies/privacy">http://www.google.de/intl/de/policies/privacy</Link>.
        </Typography>

        <Typography variant="body2" sx={styles.textBold}>
          {t("privacy8")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy8_1")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy8_2")} <Link href="https://support.google.com/tagmanager/answer/9323295?hl=de&ref_topic=3441532">https://support.google.com/tagmanager/answer/9323295?</Link>, <Link href="https://support.google.com/tagmanager/answer/6102821?hl=de">https://support.google.com/tagmanager/answer/6102821?</Link>, <Link href="https://support.google.com/tagmanager/answer/7207086?hl=de">https://support.google.com/tagmanager/answer/7207086?</Link>.
        </Typography>

        <Typography variant="body2" sx={styles.textBold}>
          {t("privacy9")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy9_1")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy9_2")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy9_3")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy9_4")} <Link href="https://www.google.com/intl/de/policies/privacy/">https://www.google.com/intl/de/policies/privacy/</Link> {t("privacy9_5")} <Link href="https://www.google.com/recaptcha/intro/android.html">https://www.google.com/recaptcha/intro/android.html</Link>.
        </Typography>

        <Typography variant="body2" sx={styles.textBold}>
          {t("privacy10")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy10_1")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy10_2")}
        </Typography>

        <List sx={styles.list}>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon style={{ fontSize: "15px" }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: { xs: 13, sm: 16, md: 19, lg: 19, xl: 19 } }} primary={t("privacy10List1")} />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon style={{ fontSize: "15px" }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: { xs: 13, sm: 16, md: 19, lg: 19, xl: 19 } }} primary={t("privacy10List2")} />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon style={{ fontSize: "15px" }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: { xs: 13, sm: 16, md: 19, lg: 19, xl: 19 } }} primary={t("privacy10List3")} />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon style={{ fontSize: "15px" }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: { xs: 13, sm: 16, md: 19, lg: 19, xl: 19 } }} primary={t("privacy10List4")} />
          </ListItem>
        </List>

        <Typography variant="body2" sx={styles.text}>
          {t("privacy10_3")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy10_4")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy10_5")}
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          <Box component="span" m="{1}" style={{ fontWeight: "bold" }}>LinkedIn</Box> (LinkedIn Ireland, Privacy Policy Issues, Wilton Plaza, Wilton Place, Dublin 2, Ireland). Privacy policy: <Link href="https://www.linkedin.com/legal/privacy-policy">https://www.linkedin.com/legal/privacy-policy</Link>. Opt-out link <Link href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out">https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out</Link>.
        </Typography>

        <Typography variant="body2" sx={styles.textBold}>
          $ 11 Leadinfo
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          {t("privacy11_1")} <Link href="http://www.leadinfo.com/">http://www.leadinfo.com/</Link>{t("privacy11_2")} <Link href="http://www.leadinfo.com/en/opt-out">http://www.leadinfo.com/en/opt-out</Link> {t("privacy11_3")}
        </Typography>

      </GridItem>
      {/* </GridItem> */}
    </GridContainer>
  )
}

export default DataProtectionPage
