import React from "react"
import { Typography } from "@mui/material"
import { Link, useLocation } from "react-router-dom"
import { useLanguage } from "../../../languageContext"
import MenuItem from "@mui/material/MenuItem"
import GridItem from '../grid/GridItem'

interface Subitem {
  name: string
  route: string
}

const styles = {
  linkHolderActive: {
    backgroundColor: "#e6f2ff",
    padding: "5px 18px",
    "&:hover": {
      backgroundColor: "#e6f2ff",
    },
  },
  linkHolderActiveMenu: {
    borderRadius: "25px",
    backgroundColor: "#e6f2ff",
    padding: "5px 18px",
    "&:hover": {
      backgroundColor: "#e6f2ff",
    },
  },
  linkHolderMenu: {
    borderRadius: "25px",
    padding: "5px 18px",
    "&:hover": {
      backgroundColor: "#e6f2ff",
    },
  },
  linkHolder1: {
    padding: "5px 18px",
    "&:hover": {
      backgroundColor: "#e6f2ff",
    },
  },
  menuItem: {
    "&:hover": {
      background: "#e6f2ff",
    },
  },
  menu: {
    "& .MuiPaper-root": {
      width: "170px",
    },
    ml: "18px",
  },
}

export default function SupportMenuMobile({ subitemRoutes }: Subitem[] | any) {
  const location = useLocation()
  const { t } = useLanguage()

  const isActiveRoute = (route: string) => {
    return location.pathname === route
  }

  return (
    <div>
      <GridItem>
        {subitemRoutes.map((route: Subitem, index: number) => (
          <MenuItem component={Link} to={route.route} key={index} sx={isActiveRoute(route.route) ? styles.linkHolderActive : styles.linkHolder1}>
            <Typography variant="body2">{t(route.name)}</Typography>
          </MenuItem>

        ))}
      </GridItem>
    </div>
  )
}
