import React, { Fragment, useState } from "react"
import Box from "@mui/material/Box"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Typography from "@mui/material/Typography"
import { Button } from "@mui/material"
import { useLanguage } from "../../../languageContext"

const styles = {
  iconTextHolder: {
    borderRadius: "25px",
    width: "115px",
    height: "43px",
    display: "flex",
    ml: "auto",
    "&:hover": {
      background: "#e6f2ff",
    },
    cursor: "pointer",
    mb: "2px",
  },
  iconText: {
    width: "115px",
    height: "42px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#666666",
  },
  menuItem: {
    color: "#346ea9",
    fontWeight: "bold",
    "&:hover": {
      background: "#346ea9",
      color: "white",
    },
  },
  menu: {
    "& .MuiPaper-root": {
      width: "60px",
    },
  },
  notificationBadge: {
    ml: "auto",
    mr: "16px",
  },
  button: {
    textTransform: "none",
    fontWeight: "bold",
    height: "32px",
    ml: "auto",
    borderRadius: "100px",
    borderColor: "#346ea9",
    color: "#346ea9",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#346ea9",
      transition: ".4s ease",
      color: "white",
    },
  },
  flag: {
    width: 10,
    mr: 2,
    cursor: "pointer",
  },
}

export default function LanguageMenu() {
  const { currentLanguage ,changeLanguage } = useLanguage()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Button
          variant="outlined"
          onClick={handleClick}
          size="small"
          sx={styles.button}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          {currentLanguage === "de" ? "DE" : "EN"}
        </Button>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={styles.menu}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={()=>changeLanguage(currentLanguage !== "de" ? "de" : "en")} sx={styles.menuItem}>
          <Typography variant="body2" sx={{ fontSize: "15px" }}>
          {currentLanguage !== "de" ? "DE" : "EN"}
          </Typography>
        </MenuItem>
      </Menu>
    </Fragment>
  )
}
