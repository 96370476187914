import React, { useState } from "react"
import Menu from "@mui/material/Menu"
import IconButton from "@mui/material/IconButton"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpSharpIcon from "@mui/icons-material/KeyboardArrowUpSharp"
import { ListItemButton, ListItemText, Typography } from "@mui/material"
import { Link, useLocation } from "react-router-dom"
import { useLanguage } from "../../../languageContext"

interface Subitem {
  name: string
  route: string
}

const styles = {
  linkHolderActive: {
    backgroundColor: "#e6f2ff",
    padding: "5px 18px",
    "&:hover": {
      backgroundColor: "#e6f2ff",
    },
  },
  linkHolderActiveMenu: {
    borderRadius: "25px",
    backgroundColor: "#e6f2ff",
    padding: "5px 18px",
    "&:hover": {
      backgroundColor: "#e6f2ff",
    },
  },
  linkHolderMenu: {
    borderRadius: "25px",
    padding: "5px 18px",
    "&:hover": {
      backgroundColor: "#e6f2ff",
    },
  },
  linkHolder1: {
    padding: "5px 18px",
    "&:hover": {
      backgroundColor: "#e6f2ff",
    },
  },
  menuItem: {
    "&:hover": {
      background: "#e6f2ff",
    },
  },
  menu: {
    "& .MuiPaper-root": {
      width: "170px",
    },
    ml: "18px",
  },
}

export default function SupportMenu({ subitemRoutes }: Subitem[] | any) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const location = useLocation()
  const { t } = useLanguage()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const isActiveRoute = (route: string) => {
    return location.pathname === route
  }

  return (
    <div>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={(location.pathname.substring(0,8) === '/support') ? styles.linkHolderActiveMenu : styles.linkHolderMenu}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <Typography variant="body2">{t("support")}</Typography>
        {!open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpSharpIcon />}
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={styles.menu}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {subitemRoutes.map((route: Subitem, index: number) => (
          <ListItemButton component={Link} to={route.route} key={index} sx={isActiveRoute(route.route) ? styles.linkHolderActive : styles.linkHolder1}>
            <ListItemText primary={<Typography variant="body2">{t(route.name)}</Typography>} />
          </ListItemButton>
        ))}
      </Menu>
    </div>
  )
}
