export const routesAppBar = [
  {
    name: "szenarien",
    route: "/home#scenarios",
  },
  {
    name: "erfolgsbausteine",
    route: "/home#success-components",
  },
  {
    name: "ihreVorteile",
    route: "/home#your-benefits",
  },
  // {
  //   name: "pricing",
  //   route: "/pricing",
  // },
]
