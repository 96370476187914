import GridContainer from '../../../components/basic/grid/GridContainer'
import GridItem from '../../../components/basic/grid/GridItem'
import { Typography } from "@mui/material"
import { ReactComponent as DataPluginsIcon } from "../../../assets/icons/squares-plus.svg"
import { ReactComponent as CloudIcon } from "../../../assets/icons/cloud.svg"
import { ReactComponent as EyeIcon } from "../../../assets/icons/eye.svg"
import { ReactComponent as DashboardsIcon } from "../../../assets/icons/rectangle-group.svg"
import { ReactComponent as CircleStackIcon } from "../../../assets/icons/circle-stack.svg"
import { ReactComponent as QueueListIcon } from "../../../assets/icons/queue-list.svg"
import { ReactComponent as TableCellsIcon } from "../../../assets/icons/table-cells.svg"
import { ReactComponent as ShieldCheckIcon } from "../../../assets/icons/shield-check.svg"
import { ReactComponent as UsersIcon } from "../../../assets/icons/users.svg"
import { ReactComponent as CubeTransparentIcon } from "../../../assets/icons/cube-transparent.svg"
import kubernetes from '../../../assets/icons/kubernetes.png'
import { ReactComponent as BellAlertIcon } from "../../../assets/icons/bell-alert.svg"
import { ReactComponent as VariableIcon } from "../../../assets/icons/variable.svg"
import nodeRed from '../../../assets/icons/node_red.png'
import { ReactComponent as Truckicon } from "../../../assets/icons/truck.svg"
import { useLanguage } from "../../../languageContext"

const styles = {
  containerFull: {
    width: "100%", height: "fit-content", display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap", justifyContent: "start"
  },
  iconAndText: {
    display: "flex",
    flexDirection: "row",
    mr: "40px",
    mb: { xs: 1, sm: 2, md: 3, lg: 3, xl: 3 },
    alignItems: "center",
  },
  textIcon: {
    color: "white",
    fontSize: "12px",
    mr: "10px",
  },
  imageIconText: {
    fontSize: { xs: 15, sm: 16, md: 19, lg: 19, xl: 19 },
  },
}

const ProductIcons = () => {
  const { t } = useLanguage()

  return (

    <GridContainer sx={[styles.containerFull]}>
      <GridItem sx={[styles.iconAndText]}>
        <GridItem sx={styles.textIcon}>
          <CloudIcon width={24} height={24} />
        </GridItem>
        <Typography variant="body2" sx={styles.imageIconText}>
          {t("privateCloud")}
        </Typography>
      </GridItem>
      <GridItem sx={[styles.iconAndText]}>
        <GridItem sx={styles.textIcon}>
          <EyeIcon width={24} height={24} />
        </GridItem>
        <Typography variant="body2" sx={styles.imageIconText}>
          {t("visualisierung")}
        </Typography>
      </GridItem>
      <GridItem sx={[styles.iconAndText]}>
        <GridItem sx={styles.textIcon}>
          <DataPluginsIcon width={24} height={24} />
        </GridItem>
        <Typography variant="body2" sx={styles.imageIconText}>
          {t("datenPlugins")}
        </Typography>
      </GridItem>
      <GridItem sx={[styles.iconAndText]}>
        <GridItem sx={styles.textIcon}>
          <DashboardsIcon width={24} height={24} />
        </GridItem>
        <Typography variant="body2" sx={styles.imageIconText}>
          {t("dashboards")}
        </Typography>
      </GridItem>
      <GridItem sx={[styles.iconAndText]}>
        <GridItem sx={styles.textIcon}>
          <ShieldCheckIcon width={24} height={24} />
        </GridItem>
        <Typography variant="body2" sx={styles.imageIconText}>
          {t("security")}
        </Typography>
      </GridItem>
      <GridItem sx={[styles.iconAndText]}>
        <GridItem sx={styles.textIcon}>
          <QueueListIcon width={24} height={24} />
        </GridItem>
        <Typography variant="body2" sx={styles.imageIconText}>
          {t("geratemanagement")}
        </Typography>
      </GridItem>
      <GridItem sx={[styles.iconAndText]}>
        <GridItem sx={styles.textIcon}>
          <CircleStackIcon width={24} height={24} />
        </GridItem>
        <Typography variant="body2" sx={styles.imageIconText}>
          {t("zeitreihenDatenbank")}
        </Typography>
      </GridItem>
      <GridItem sx={[styles.iconAndText]}>
        <GridItem sx={styles.textIcon}>
          <TableCellsIcon width={24} height={24} />
        </GridItem>
        <Typography variant="body2" sx={styles.imageIconText}>
          {t("datenmanagement")}
        </Typography>
      </GridItem>
      <GridItem sx={[styles.iconAndText]}>
        <GridItem sx={styles.textIcon}>
          <UsersIcon width={24} height={24} />
        </GridItem>
        <Typography variant="body2" sx={styles.imageIconText}>
          {t("userRolenManagement")}
        </Typography>
      </GridItem>
      <GridItem sx={[styles.iconAndText]}>
        <GridItem sx={styles.textIcon}>
          <BellAlertIcon width={24} height={24} />
        </GridItem>
        <Typography variant="body2" sx={styles.imageIconText}>
          {t("notifications")}
        </Typography>
      </GridItem>
      <GridItem sx={[styles.iconAndText]}>
        <GridItem sx={styles.textIcon}>
          <VariableIcon width={24} height={24} />
        </GridItem>
        <Typography variant="body2" sx={styles.imageIconText}>
          {t("ruleEngineLogik")}
        </Typography>
      </GridItem>
      <GridItem sx={[styles.iconAndText]}>
        <GridItem sx={styles.textIcon}>
          <Truckicon width={24} height={24} />
        </GridItem>
        <Typography variant="body2" sx={styles.imageIconText}>
          {t("flottenmanagement")}
        </Typography>
      </GridItem>
      <GridItem sx={[styles.iconAndText]}>
        <GridItem sx={styles.textIcon}>
          <img
            src={`${nodeRed}`}
            alt="Industrial Automation"
            style={{ width: "24px", height: "24px" }}
          />
        </GridItem>
        <Typography variant="body2" sx={styles.imageIconText}>
          {t("nodeRed")}
        </Typography>
      </GridItem>
      <GridItem sx={[styles.iconAndText]}>
        <GridItem sx={styles.textIcon}>
          <img
            src={`${kubernetes}`}
            alt="Industrial Automation"
            style={{ width: "24px", height: "24px" }}
          />
        </GridItem>
        <Typography variant="body2" sx={styles.imageIconText}>
          {t("kubernetes")}
        </Typography>
      </GridItem>
      <GridItem sx={[styles.iconAndText]}>
        <GridItem sx={styles.textIcon}>
          <CubeTransparentIcon width={24} height={24} />
        </GridItem>
        <Typography variant="body2" sx={styles.imageIconText}>
          {t("whiteLabeling")}
        </Typography>
      </GridItem>
    </GridContainer>

  )
}
export default ProductIcons
