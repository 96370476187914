import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import "../../customCss/mui-styles.css"
import { useLanguage } from "../../languageContext"

const styles = {
  leftLineGray: {
    borderLeft: "1px solid #888888", pl: "12px", 
    paddingBottom: { xs: "4px", sm: "0", md: "0", lg: "0", xl: "0" },
    paddingTop: { xs: "4px", sm: "4px", md: "0", lg: "0", xl: "0" },
  },
  leftLineBlue: {
    borderLeft: "1px solid #346EA9", pl: "12px", pt: "7px"
  },
  text: {
    fontSize: { xs: "15px", sm: "15px", md: "16px", lg: "18px", xl: "18px" }, 
    backgroundColor: "#212931", color: "#F6F9FC"
  },
  grayBoldText: {
    color: "#F6F9FC", fontWeight: "bold", 
    fontSize: { xs: "16px", sm: "16px", md: "18px", lg: "20px", xl: "20px" }, 
  }
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0`,
  outline: "none",
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#212931",
  flexDirection: 'row-reverse',
  padding: "0",
  border: "0",
  outline: "none",
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "0",
  border: "0",
  outline: "none",
}));

export default function CustomizedAccordions() {
  const { t } = useLanguage()
  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography variant="body2" sx={[styles.grayBoldText, styles.leftLineGray]}>
            {t("dasMultifunktions")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" sx={[styles.text, styles.leftLineBlue]}>
            {t("datenerfassung")}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography variant="body2" sx={[styles.grayBoldText, styles.leftLineGray]}>
            {t("allesAusEinerHand")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" sx={[styles.text, styles.leftLineBlue]}>
            {t("alsFullStackPlatform")}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography variant="body2" sx={[styles.grayBoldText, styles.leftLineGray]}>
            {t("soSicherWie")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" sx={[styles.text, styles.leftLineBlue]}>
            {t("keinVertraunen")} 
          </Typography>
          <Typography variant="body2" sx={[styles.text, styles.leftLineBlue]}>
            <span style={{ fontStyle: "italic" }}>{t("keinVertraunenItalic")}</span>.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography variant="body2" sx={[styles.grayBoldText, styles.leftLineGray]}>
            {t("datenNutzen")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" sx={[styles.text, styles.leftLineBlue]}>
            {t("ganzGleich")}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <Typography variant="body2" sx={[styles.grayBoldText, styles.leftLineGray]}>
            {t("nullKompromisse")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" sx={[styles.text, styles.leftLineBlue]}>
            {t("mitSiotAlsAllInOne")}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
