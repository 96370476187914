import React, { useEffect } from "react"
import { useLanguage } from "../../../languageContext"

const MeetingWidget = () => {
  const { currentLanguage } = useLanguage()

  const dataUrl = currentLanguage === "en" ? "https://calendly.com/anton-guggenhuber/siot-potential-check-en" : "https://calendly.com/anton-guggenhuber/siot-potential-check"

  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://assets.calendly.com/assets/external/widget.js"
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [currentLanguage])

  return (
    <div>
      <div
        className="calendly-inline-widget"
        data-url={dataUrl}
        style={{ minWidth: "320px", height: "700px" }}
      />
    </div>
  )
}

export default MeetingWidget
