import { CssBaseline } from "@mui/material"
import React from "react"
import "./App.css"
import AppRouter from "./router/AppRouter"

function App() {
  return (
    <div>
      <CssBaseline />
      <AppRouter />
    </div>
  )
}

export default App
