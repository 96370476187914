import { Link, useNavigate, useLocation } from "react-router-dom"
import GridContainer from "../../components/basic/grid/GridContainer"
import GridItem from "../../components/basic/grid/GridItem"
import { Button, List, Link as MuiLink, ListItemButton, ListItemText, Typography } from "@mui/material"
import { ReactComponent as SiotLogo } from "../../assets/images/siotLogo.svg"
import { routesAppBar } from "./navigation/Routes"
import { HashLink } from "react-router-hash-link"
import SupportMenu from "../basic/menu/SupportMenu"
import { useLanguage } from "../../languageContext"
import TopMenu from "../basic/menu/TopMenu"

interface Route {
  name: string
  route?: string
  subitems?: Subitem[]
}

interface Subitem {
  name: string
  route: string
}

const styles = {
  containerFull1: {
    width: "100%",
    height: "fit-content",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "center",
    display: { xs: "none", sm: "none", md: "none", lg: "flex", xl: "flex" },
    backgroundColor: "#F2F4FA",
  },
  containerFull2: {
    width: "100%",
    height: "fit-content",
    flexDirection: "row",
    alignItems: "start",
    flexWrap: "wrap",
    justifyContent: "center",
    display: { xs: "flex", sm: "flex", md: "flex", lg: "none", xl: "none" },
    backgroundColor: "#F2F4FA",
  },
  container1070: {
    width: { xs: "90%", sm: "85%", md: "80%", lg: "80%", xl: "80%" },
    maxWidth: { xs: "90%", sm: "85%", md: "80%", lg: "80%", xl: 1300 },
    margin: "0 auto",
    mb: "15px",
    flex: 1,
    flexWrap: "wrap",
    justifyContent: "left",
    alignItems: "center",
    pt: "20px",
  },
  container10702: {
    width: { xs: "90%", sm: "85%", md: "80%", lg: "80%", xl: "80%" },
    maxWidth: { xs: "90%", sm: "85%", md: "80%", lg: "80%", xl: 1300 },
    margin: "0 auto",
    mb: "15px",
    flex: 1,
    flexWrap: "wrap",
    justifyContent: "left",
    alignItems: "start",
    flexDirection: "row",
  },
  footerLinks: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: { xs: 400, sm: 590, md: 200, lg: "45%", xl: "45%" },
  },
  footerLinksBottom: {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    justifyContent: "center",
    mb: "30px",
    fontSize: "14px",
  },
  footerRow: {
    width: "100%",
    maxWidth: "100%",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    height: "76px",
  },
  button: {
    textTransform: "none",
    fontWeight: "bold",
    height: "32px",
    ml: "auto",
    px: "15px",
    borderRadius: "100px",
    borderColor: "#346ea9",
    color: "#346ea9",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#346ea9",
      transition: ".4s ease",
      color: "white",
    },
  },
  button1: {
    textTransform: "none",
    fontWeight: "bold",
    height: "32px",
    px: "15px",
    mr: "10px",
    mb: { xs: "10px", sm: "10px", md: "0px", lg: "0px", xl: "0px" },
    fontSize: { xs: "13px", sm: "14px", md: "14px", lg: "14px", xl: "14px" },
    borderRadius: "100px",
    backgroundColor: "#F2F4FA",
    color: "#346ea9",
    border: "1px solid #346ea9",
    "&:hover": {
      backgroundColor: "#346ea9",
      transition: ".4s ease",
      color: "#F2F4FA",
    },
  },
  links: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    ml: "20px",
    color: "#666666",
  },
  linkHolderActive: {
    textAlign: "center",
    height: "34px",
    mt: "6px",
    backgroundColor: "#e6f2ff",
    borderRadius: "25px",
    padding: "5px 10px",
    "&:hover": {
      backgroundColor: "#e6f2ff",
      color: "black",
    },
  },
  linkHolder: {
    textAlign: "center",
    height: "34px",
    mt: "6px",
    borderRadius: "25px",
    padding: "5px 10px",
    "&:hover": {
      backgroundColor: "#e6f2ff",
      color: "black",
    },
  },
  menu: {
    mt: "6px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#666666",
    padding: "5px 0px",
  },
  footerColumnLeft: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    alignItems: "start",
  },
  footerColumnRight: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    alignItems: "end",
    justifyItems: "start",
    pt: "10px",
  },
  linksColumn: {
    display: { xs: "none", sm: "none", md: "none", lg: "flex", xl: "flex" },
    flexDirection: "column",
    width: "50%",
    alignItems: "end",
    textWrap: "nowrap",
  },
  buttonColumns: {
    textTransform: "none",
    fontWeight: "bold",
    height: "32px",
    px: "15px",
    borderRadius: "100px",
    borderColor: "#346ea9",
    color: "#346ea9",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#346ea9",
      transition: ".4s ease",
      color: "white",
    },
  },
  footerLinksBottomColumns: {
    height: "55px",
    cursor: "pointer",
    alignItems: "start",
    justifyContent: "start",
    justifyitems: "start",
    display: "flex",
  },
  linksImpressum: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textColumn: {
    fontSize: { xs: "12px", sm: "13px", md: "13px", lg: "14px", xl: "14px" },
    fontWeight: "bold",
  },
  footerGrid: {
    backgroundColor: "#F2F4FA",
    display: "flex",
    height: "40px",
    justifyContent: "center",
    alignItems: "center",
  },
}

const Footer = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useLanguage()
  const settings: any = JSON.parse(localStorage.getItem("settings") || "")

  const isActiveRoute = (route: string) => {
    return location.pathname === route
  }

  return (
    <>
      <GridContainer sx={[styles.containerFull1]}>
        <GridContainer sx={[styles.container1070]}>
          <GridContainer sx={[styles.footerRow]}>
            <GridItem
              sx={{
                width: { xs: "110px", sm: "150px", md: "150px", lg: "155px", xl: "155px" },
                cursor: "pointer",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
              onClick={() => navigate("/home")}
            >
              <SiotLogo />
              <Typography
                variant="body2"
                sx={{ letterSpacing: "0.7px", fontSize: { xs: "7px", sm: "11px", md: "11px", lg: "11px", xl: "11px" }, color: "black" }}
              >
                powered by Appmodule AG
              </Typography>
            </GridItem>

            <GridItem sx={styles.footerLinks} style={{ fontSize: "14px" }}>
              <List sx={styles.links} component="div" disablePadding>
                {routesAppBar.map((route: Route, index: number) =>
                  route.route !== undefined ? (
                    route.route === "/home#scenarios" || route.route === "/home#success-components" || route.route === "/home#your-benefits" ? (
                      <ListItemButton
                        component={HashLink}
                        key={index}
                        smooth
                        to={route.route}
                        sx={isActiveRoute(route.route) ? styles.linkHolderActive : styles.linkHolder}
                      >
                        {t(route.name)}
                      </ListItemButton>
                    ) : (
                      <ListItemButton
                        component={Link}
                        to={route.route}
                        key={index}
                        sx={isActiveRoute(route.route) ? styles.linkHolderActive : styles.linkHolder}
                      >
                        {t(route.name)}
                      </ListItemButton>
                    )
                  ) : (
                    <GridItem sx={styles.menu} key={index}>
                      <SupportMenu subitemRoutes={route.subitems} />
                    </GridItem>
                  )
                )}
              </List>
            </GridItem>

            <GridItem>
              <Button component={MuiLink} underline="none" href={settings.IOTOOLS_BP_FRONT_URL} sx={styles.button1}>
                {t("trySiotNow")}
              </Button>
              {/* <Button variant="outlined" onClick={() => navigate("/meeting")} sx={styles.button}>
                {t("beratungsgespräch")}
              </Button> */}
            </GridItem>
          </GridContainer>
          <GridContainer sx={[styles.footerRow]} style={{ marginTop: "30px" }}>
            <GridItem>
            </GridItem>
            <GridItem sx={styles.footerLinksBottom}>
              <List sx={styles.links} component="div" disablePadding style={{ fontSize: "14px" }}>
                <ListItemButton component={Link} to={"/impressum"} sx={styles.linkHolder}>
                  {t("impressum")}
                </ListItemButton>
                |
                <ListItemButton component={Link} to={"/privacy-policy"} sx={styles.linkHolder}>
                  {t("datenschutz")}
                </ListItemButton>
              </List>
            </GridItem>
          </GridContainer>
        </GridContainer>
      </GridContainer>

      <GridContainer sx={[styles.containerFull2]}>
        <GridContainer sx={[styles.container10702]}>
          <GridContainer sx={[styles.footerColumnLeft]}>
            <GridItem
              sx={{
                width: { xs: "110px", sm: "150px", md: "150px", lg: "155px", xl: "155px" },
                cursor: "pointer",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                marginBottom: "20px",
                marginTop: "20px",
              }}
              onClick={() => navigate("/home")}
            >
              <SiotLogo />
              <Typography
                variant="body2"
                sx={{ letterSpacing: "0.7px", fontSize: { xs: "7px", sm: "11px", md: "11px", lg: "11px", xl: "11px" }, color: "black" }}
              >
                powered by Appmodule AG
              </Typography>
            </GridItem>

            <GridItem>
              <Button component={MuiLink} underline="none" href={settings.IOTOOLS_BP_FRONT_URL} sx={styles.button1}>
                {t("trySiotNow")}
              </Button>
              {/* <Button variant="outlined" onClick={() => navigate("/meeting")} sx={styles.buttonColumns}>
                {t("beratungsgespräch")}
              </Button> */}
            </GridItem>
          </GridContainer>

          <GridContainer sx={styles.footerColumnRight}>
            <GridItem sx={{ display: { xs: "block", sm: "block", md: "block", lg: "none", xl: "none" }, marginRight: "-10px" }}>
              <TopMenu />
            </GridItem>

            <List sx={styles.linksColumn} component="div" disablePadding>
              {routesAppBar.map((route: Route, index: number) =>
                route.route !== undefined ? (
                  route.route === "/home#scenarios" || route.route === "/home#success-components" || route.route === "/home#your-benefits" ? (
                    <ListItemButton
                      component={HashLink}
                      key={index}
                      smooth
                      to={route.route}
                      sx={isActiveRoute(route.route) ? styles.linkHolderActive : styles.linkHolder}
                    >
                      {t(route.name)}
                    </ListItemButton>
                  ) : (
                    <ListItemButton
                      component={Link}
                      to={route.route}
                      key={index}
                      sx={isActiveRoute(route.route) ? styles.linkHolderActive : styles.linkHolder}
                    >
                      <ListItemText
                        primary={
                          <Typography sx={isActiveRoute(route.route) ? { color: "black" } : {}} variant="body2">
                            {t(route.name)}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  )
                ) : (
                  <GridItem sx={styles.menu} key={index}>
                    <SupportMenu subitemRoutes={route.subitems} />
                  </GridItem>
                )
              )}
            </List>

            <GridItem sx={styles.footerLinksBottomColumns}>
              <List
                sx={styles.linksImpressum}
                component="div"
                disablePadding
                style={{ fontSize: "14px", flexDirection: "column", alignItems: "end" }}
              >
                <ListItemButton component={Link} to={"/impressum"} sx={styles.linkHolder}>
                  {t("impressum")}
                </ListItemButton>

                <ListItemButton component={Link} to={"/privacy-policy"} sx={styles.linkHolder}>
                  {t("datenschutz")}
                </ListItemButton>
              </List>
            </GridItem>
          </GridContainer>
        </GridContainer>
      </GridContainer>
      <GridItem sx={styles.footerGrid}>
        <Typography variant="body2" sx={styles.textColumn}>
          © 2024 Appmodule AG. {t("rechte")}
        </Typography>
      </GridItem>
    </>
  )
}
export default Footer
