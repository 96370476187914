import * as React from "react"
import Box from "@mui/material/Box"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import { Button, Typography } from "@mui/material"
import GridItem from "../grid/GridItem"
import ContainedButton from "../buttons/ContainedButton"
import { Link } from "react-router-dom"
import { useLanguage } from "../../../languageContext"

type Anchor = "top" | "left" | "bottom" | "right"

interface AgreementState {
  top: boolean
  left: boolean
  bottom: boolean
  right: boolean
}

interface Props {
  state: AgreementState
  toggleDrawer: any
}

const styles = {
  container: {
    width: { xs: "90%", sm: "85%", md: "80%", lg: "80%", xl: "80%" },
    maxWidth: { xs: "90%", sm: "85%", md: "80%", lg: "80%", xl: 1315 },
    margin: "0 auto",
    mb: "15px",
    flex: 1,
    flexWrap: "wrap",
    justifyContent: "left",
  },
  link: {
    color: "#346EA9",
    "&:hover": {
      textDecoration: "none",
    },
  },
  button: {
    textTransform: "none",
    height: "32px",
    borderRadius: "10px",
    border: "1px solid #346ea9",
    color: "#346ea9",
    mr: "20px",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#346ea9",
      transition: ".4s ease",
      color: "white",
    },
  },
}

const Agreement = ({ state, toggleDrawer }: Props) => {
  const { t } = useLanguage()

  React.useEffect(() => {
    document.body.style.overflow = state.bottom ? "auto" : ""
    return () => {
      document.body.style.overflow = ""
    }
  }, [state.bottom])

  const list = (anchor: Anchor) => (
    <Box sx={{ width: "auto", height: "auto", backgroundColor: "#fff", paddingY: "20px", overflow: "auto" }} role="presentation">
      <GridItem sx={styles.container}>
        <Typography variant="h4" component="div" sx={{ flexGrow: 1, paddingBottom: "15px" }}>
          {t("privacyCookies")}
        </Typography>
        <Typography sx={{ flexGrow: 1, paddingBottom: "15px" }}>
          {t("weUseCookies")}&nbsp;
          <Typography component={Link} sx={styles.link} to="/privacy-policy">
            {t("learnMore")}
          </Typography>
        </Typography>
        <Button sx={styles.button} onClick={toggleDrawer(anchor, false)}>
          {t("yesAgree")}
        </Button>
        <Button sx={styles.button} onClick={toggleDrawer(anchor, false)}>
          {t("noDisagree")}
        </Button>
      </GridItem>
    </Box>
  )

  return (
    <div>
      <React.Fragment>
        <SwipeableDrawer anchor={"bottom"} open={state["bottom"]} onClose={toggleDrawer("bottom", true)} onOpen={toggleDrawer("bottom", true)}>
          {list("bottom")}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  )
}

export default Agreement
