import React from "react"
import { createBrowserRouter, Navigate, Outlet, RouterProvider } from "react-router-dom"
import Navigation from "../components/advanced/navigation/Navigation"
import HomePage from "../pages/appbarPages/HomePage"
import MeetingWidget from "../components/advanced/widgets/MeetingWidget"
import ImpressumPage from "../pages/mainPages/ImpressumPage"
import PrivacyPolicyPage from "../pages/mainPages/PrivacyPolicyPage"
import ScrollToTop from "../ScrollToTop"
import MobilePrivacyPolicy from "../pages/mainPages/MobilePrivacyPolicy"
import MobileTermsPage from "../pages/mainPages/MobileTermsPage"


const allRoutes = [
  {
    path: "home",
    element: <HomePage />,
  },
  {
    path: "meeting",
    element: <MeetingWidget />,
  },
  // {
  //   path: "pricing",
  //   element: <PricingPage />,
  // },
  {
    path: "impressum",
    element: <ImpressumPage />,
  },
  {
    path: "privacy-policy",
    element: <PrivacyPolicyPage />,
  },
  {
    path: "*",
    element: <Navigate replace to={"/home"} />,
  },
]

export default function AppRouter() {
  const router = React.useMemo(() => {
    const viewerApp = {
      element: <Navigation />,
      children: [...allRoutes],
    }

    return createBrowserRouter([
      {
        path: "/",
        element: <Navigate to="/home" replace={true} />,
      },
      {
        path: "/",
        element: (
          <ScrollToTop>
            <Outlet />
          </ScrollToTop>
        ),
        children: [viewerApp],
      },
      {
        path: "legal/privacy_policy",
        element: <MobilePrivacyPolicy />,
      },
      {
        path: "legal/terms_and_conditions",
        element: <MobileTermsPage />,
      }
    ])
  }, [])

  return <RouterProvider router={router} />
}
